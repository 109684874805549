<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-8">
              <h3 class="mb-0">
                {{ purchasesOrder.seq_number ?? purchasesOrder.code }}
                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="editPurchasesOrder()"
                  v-if="purchasesOrder.status === ORDER_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-edit"></i>
                  </span>
                </base-button>

                <base-button
                  v-if="
                    purchasesOrder.status === ORDER_STATUS_DRAFT &&
                    $currentUserCan($permissions.PERM_DELETE_PURCHASES_ORDERS)
                  "
                  type="danger"
                  icon
                  size="sm"
                  @click="deletePurchasesOrder()"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-trash"></i>
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="validatePurchasesOrder()"
                  :disabled="loading"
                  v-if="
                    purchasesOrder.items.length > 0 &&
                    purchasesOrder.status === ORDER_STATUS_DRAFT
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-check-bold"></i>
                    {{ $t("PURCHASES_ORDERS.VALIDATE") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="revertToDraft()"
                  :disabled="loading"
                  v-if="purchasesOrder.status === ORDER_STATUS_VALIDATED"
                >
                  <span class="btn-inner--icon">
                    <i class="fas fa-undo"></i>
                    {{ $t("PURCHASES_ORDERS.REVERT_TO_DRAFT") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      purchasesOrderInvoiceFormModalOpened = true;
                    }
                  "
                  :disabled="loading"
                  v-if="
                    purchasesOrder.status == ORDER_STATUS_VALIDATED &&
                    purchasesOrder.invoicing_status != INVOICING_STATUS_INVOICED
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-single-copy-04"></i>
                    {{ $t("PURCHASES_ORDERS.ADD_INVOICE") }}
                  </span>
                </base-button>

                <base-button
                  type="primary"
                  icon
                  size="sm"
                  @click="
                    () => {
                      purchasesOrderDeliveryFormModalOpened = true;
                    }
                  "
                  :disabled="loading"
                  v-if="
                    purchasesOrder.status == ORDER_STATUS_VALIDATED &&
                    purchasesOrder.delivery_status != DELIVERY_STATUS_DELIVERED
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-truck"></i>
                    {{ $t("PURCHASES_ORDERS.ADD_DELIVERY") }}
                  </span>
                </base-button>

                <base-button
                  type="danger"
                  icon
                  size="sm"
                  @click="cancelPurchasesOrder()"
                  :disabled="loading"
                  v-if="canCancelPurchasesOrder"
                >
                  <span class="btn-inner--icon">
                    <i class="ni ni-fat-remove"></i>
                    {{ $t("PURCHASES_ORDERS.CANCEL") }}
                  </span>
                </base-button>

                <base-button
                  type="info"
                  icon
                  size="sm"
                  @click="print()"
                  :disabled="loading"
                  v-if="purchasesOrder.status !== ORDER_STATUS_DRAFT"
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-file-pdf"></i>
                    {{ $t("COMMON.PRINT") }}
                  </span>
                </base-button>

                <base-button
                  type="success"
                  icon
                  size="sm"
                  @click="toggleMailModal()"
                  :disabled="loading"
                  v-if="
                    purchasesOrder.status !== ORDER_STATUS_DRAFT &&
                    supplyRule?.order_method == METHOD_EMAIL
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-envelope"></i>
                    {{ $t("COMMON.SEND") }}
                  </span>
                </base-button>

                <base-button
                  type="success"
                  icon
                  size="sm"
                  @click="copyTextAndOpenUrlDube()"
                  :disabled="loading"
                  v-if="
                    isDube && purchasesOrder.status == ORDER_STATUS_VALIDATED
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-copy"></i>
                    {{ $t("COMMON.ORDER_DUBE") }}
                  </span>
                </base-button>

                <base-button
                  type="success"
                  icon
                  size="sm"
                  @click="downloadCsvFile()"
                  :disabled="loading"
                  v-if="
                    isMegaburo &&
                    purchasesOrder.status == ORDER_STATUS_VALIDATED
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-copy"></i>
                    {{ $t("COMMON.ORDER_MEGABURO") }}
                  </span>
                </base-button>
                <base-button
                  type="success"
                  icon
                  size="sm"
                  @click="downloadCsvFileWithHeader()"
                  :disabled="loading"
                  v-if="
                    isPepsico && purchasesOrder.status == ORDER_STATUS_VALIDATED
                  "
                >
                  <span class="btn-inner--icon">
                    <i class="fa fa-copy"></i>
                    Pepsico
                  </span>
                </base-button>
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack()"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>

              <notification-subscription
                :objectType="'purchases-orders'"
                :objectId="purchasesOrder.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>
        <div>
          <div
            class="col-12 justify-content-center justify-content-sm-between flex-wrap tabs-wrapper"
          >
            <tabs
              fill
              class="flex-column flex-md-row"
              tabNavWrapperClasses="nav-wrapper"
              tabNavClasses="nav nav-pills nav-fill"
              value="details"
            >
              <card shadow>
                <tab-pane title="global" id="1" :active="false">
                  <span slot="title">
                    <i class="ni ni-cloud-upload-96" />
                    {{ $t("COMMON.GLOBAL") }}
                  </span>
                  <purchases-order-view-global
                    :purchasesOrder="purchasesOrder"
                    :supplyRule="supplyRule"
                  />
                </tab-pane>

                <tab-pane title="details" id="2" :active="true">
                  <span slot="title">
                    <i class="ni ni-bullet-list-67" />
                    {{ $t("COMMON.DETAILS") }}
                  </span>
                  <purchases-order-view-details
                    :purchasesOrder="purchasesOrder"
                    @purchasesOrderItemsUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="details" id="3" :active="false">
                  <span slot="title">
                    <i class="ni ni-single-copy-04" />
                    {{ $t("PURCHASES_ORDERS.PURCHASES_INVOICES") }}
                  </span>
                  <purchases-order-view-invoices
                    :purchasesOrder="purchasesOrder"
                    @purchasesOrderInvoicesUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="details" id="4" :active="false">
                  <span slot="title">
                    <i class="fa fa-truck" />
                    {{ $t("PURCHASES_ORDERS.DELIVERIES_STATE") }}
                  </span>
                  <purchases-order-view-delivery-state
                    :purchasesOrder="purchasesOrder"
                    @purchasesOrderDeliveriesUpdated="get"
                  />
                </tab-pane>

                <tab-pane title="details" id="5" :active="false">
                  <span slot="title">
                    <i class="fa fa-truck" />
                    {{ $t("COMMON.PURCHASES_DELIVERIES") }}
                  </span>
                  <purchases-order-view-deliveries
                    :purchasesOrder="purchasesOrder"
                    @purchasesOrderDeliveriesUpdated="get"
                  />
                </tab-pane>

                <tab-pane
                  title="files"
                  id="998"
                  v-if="
                    $currentUserCan($permissions.PERM_VIEW_ANY_FILES) &&
                    purchasesOrder.organization
                  "
                >
                  <span slot="title">
                    <i class="ni ni-folder-17" />
                    {{ $t("COMMON.FILES") }}
                  </span>
                  <list-file-component :object="purchasesOrder" />
                </tab-pane>

                <tab-pane
                  title="logs"
                  id="999"
                  v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
                >
                  <span slot="title">
                    <i class="fa fa-file" />
                    {{ $t("COMMON.LOGS") }}
                  </span>
                  <purchases-order-view-logs :purchasesOrder="purchasesOrder" />
                </tab-pane>
              </card>
            </tabs>
          </div>
        </div>
      </card>
    </div>

    <purchases-order-view-add-delivery-form
      v-if="!purchasesOrder.deliveryItemsState.orderDelivered"
      :purchasesOrder="purchasesOrder"
      :showModal="purchasesOrderDeliveryFormModalOpened"
      @closePurchasesOrderDeliveryForm="
        () => {
          purchasesOrderDeliveryFormModalOpened = false;
        }
      "
    />

    <purchases-order-view-add-invoice-form
      v-if="!purchasesOrder.invoicingItemsState.orderInvoiced"
      :purchasesOrder="purchasesOrder"
      :showModal="purchasesOrderInvoiceFormModalOpened"
      @closePurchasesOrderInvoiceForm="
        () => {
          purchasesOrderInvoiceFormModalOpened = false;
        }
      "
    />

    <modal
      :show="showMailModal"
      modal-classes="modal-secondary"
      size="xl"
      v-if="purchasesOrder.organization"
    >
      <purchase-order-mail-modal-content
        :purchasesOrder="purchasesOrder"
        :get="get"
        :toggleMailModal="toggleMailModal"
      />
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { cloneDeep } from "lodash";
import swal from "sweetalert2";
import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_DRAFT,
  ORDER_STATUS_VALIDATED,
  INVOICING_STATUS_INVOICED,
  DELIVERY_STATUS_DELIVERED,
} from "@/constants/orders";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ListFileComponent from "@/components/File/ListFileComponent.vue";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PurchasesOrderViewGlobal from "./partials/PurchasesOrderViewGlobal.vue";
import PurchasesOrderViewDetails from "./partials/PurchasesOrderViewDetails.vue";
import PurchasesOrderViewLogs from "./partials/PurchasesOrderViewLogs.vue";
import defaultPurchasesOrder from "./defaultPurchasesOrder";
import PurchasesOrderViewAddDeliveryForm from "./partials/PurchasesOrderViewAddDeliveryForm.vue";
import PurchasesOrderViewAddInvoiceForm from "./partials/PurchasesOrderViewAddInvoiceForm.vue";
import PurchasesOrderViewDeliveries from "./partials/PurchasesOrderViewDeliveries.vue";
import PurchasesOrderViewDeliveryState from "./partials/PurchasesOrderViewDeliveryState.vue";
import PurchasesOrderViewInvoices from "./partials/PurchasesOrderViewInvoices.vue";
import PurchaseOrderMailModalContent from "./partials/PurchaseOrderMailModalContent";
import { METHOD_EMAIL } from "@/constants/supplyRules";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    PurchasesOrderViewGlobal,
    PurchasesOrderViewDetails,
    PurchasesOrderViewLogs,
    ListFileComponent,
    NotificationSubscription,
    PurchasesOrderViewAddDeliveryForm,
    PurchasesOrderViewAddInvoiceForm,
    PurchasesOrderViewDeliveries,
    PurchasesOrderViewDeliveryState,
    PurchasesOrderViewInvoices,
    PurchaseOrderMailModalContent,
  },

  mixins: [requestErrorMixin],

  data() {
    return {
      loading: false,
      showMailModal: false,
      purchasesOrder: cloneDeep(defaultPurchasesOrder),
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      ORDER_STATUS_VALIDATED: ORDER_STATUS_VALIDATED,
      INVOICING_STATUS_INVOICED: INVOICING_STATUS_INVOICED,
      DELIVERY_STATUS_DELIVERED: DELIVERY_STATUS_DELIVERED,
      purchasesOrderDeliveryFormModalOpened: false,
      purchasesOrderInvoiceFormModalOpened: false,
      supplyRule: null,
      METHOD_EMAIL,
    };
  },

  computed: {
    canCancelPurchasesOrder() {
      if (
        this.purchasesOrder.status === ORDER_STATUS_CANCELED ||
        this.purchasesOrder.status === ORDER_STATUS_DRAFT
      ) {
        return false;
      }
      if (this.purchasesOrder.status === ORDER_STATUS_VALIDATED) {
        return true;
      }
      return false;
    },
    isDube() {
      if (!this.purchasesOrder.issuer) {
        return false;
      }
      if (!this.purchasesOrder.issuer.company_name) {
        return false;
      }
      return this.purchasesOrder.issuer.company_name.includes("Dubé Loiselle");
    },
    isPepsico() {
      if (!this.purchasesOrder.issuer) {
        return false;
      }
      if (!this.purchasesOrder.issuer.company_name) {
        return false;
      }
      return this.purchasesOrder.issuer.company_name.includes("Pepsico");
    },
    isMegaburo() {
      if (!this.purchasesOrder.issuer) {
        return false;
      }
      if (!this.purchasesOrder.issuer.company_name) {
        return false;
      }
      return this.purchasesOrder.issuer.company_name.includes("Mégaburo");
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    toggleMailModal() {
      this.showMailModal = !this.showMailModal;
    },

    async get() {
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("purchasesOrders/get", id);
        this.purchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];

        const establishment = this.purchasesOrder.establishment;
        const issuer = this.purchasesOrder.issuer;
        await this.$store.dispatch("supplyRules/list", {
          filter: {
            ...(establishment ? { establishment: establishment.id } : {}),
            ...(issuer ? { supplier: issuer.id } : {}),
          },
        });
        this.supplyRule = this.$store.getters["supplyRules/list"][0];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async editPurchasesOrder(row) {
      this.$router.push({
        name: "Edit PurchasesOrder",
        params: { id: this.purchasesOrder.id },
      });
    },

    async validatePurchasesOrder() {
      try {
        const orderAmount = this.purchasesOrder.pricing.total;
        if (this.supplyRule) {
          if (orderAmount < this.supplyRule.min_purchase) {
            const confirm = await swal.fire({
              title: this.$t(`ERRORS.SUPPLIER_X_HAS_X_MIN_PURCHASE`, {
                supplierName: this.purchasesOrder.issuer.company_name,
                minPurchase: this.$formatCurrency(this.supplyRule.min_purchase),
              }),
              icon: "error",
              confirmButtonText: this.$t("COMMON.OK"),
              cancelButtonText: this.$t("COMMON.CANCEL"),
              showCancelButton: true,
              confirmButtonClass: "btn btn-primary",
              cancelButtonClass: "btn btn-warning",
            });
            if (!confirm.isConfirmed) {
              return;
            }
          }
          if (orderAmount > this.supplyRule.max_purchase) {
            const confirm = await swal.fire({
              title: this.$t(`ERRORS.SUPPLIER_X_HAS_X_MAX_PURCHASE`, {
                supplierName: this.purchasesOrder.issuer.company_name,
                minPurchase: this.$formatCurrency(this.supplyRule.max_purchase),
              }),
              icon: "error",
              confirmButtonText: this.$t("COMMON.OK"),
              cancelButtonText: this.$t("COMMON.CANCEL"),
              showCancelButton: true,
              confirmButtonClass: "btn btn-primary",
              cancelButtonClass: "btn btn-warning",
            });
            if (!confirm.isConfirmed) {
              return;
            }
          }
        }

        this.loading = true;
        await this.$store.dispatch(
          "purchasesOrders/validate",
          this.purchasesOrder.id
        );
        this.purchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_VALIDATED"),
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async revertToDraft() {
      try {
        const confirm = await swal.fire({
          title: this.$t(`PURCHASES_ORDERS.CONFIRM_REVERT_TO_DRAFT`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          cancelButtonText: this.$t("COMMON.CANCEL"),
          showCancelButton: true,
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (!confirm.isConfirmed) {
          return;
        }

        this.loading = true;
        await this.$store.dispatch(
          "purchasesOrders/revertToDraft",
          this.purchasesOrder.id
        );
        this.purchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_REVERTED"),
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async cancelPurchasesOrder() {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_ORDERS.CANCEL_PURCHASES_ORDER_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      if (!confirmation.value) {
        return;
      }

      try {
        this.loading = true;
        await this.$store.dispatch(
          "purchasesOrders/cancel",
          this.purchasesOrder.id
        );
        this.purchasesOrder =
          this.$store.getters["purchasesOrders/purchasesOrder"];
        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_CANCELED"),
        });
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async deletePurchasesOrder() {
      const confirmation = await swal.fire({
        title: this.$t("PURCHASES_ORDERS.DELETE_THIS_PURCHASES_ORDER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "purchasesOrders/destroy",
            this.purchasesOrder.id
          );
          await this.goBack();
          this.$notify({
            type: "success",
            message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "purchasesOrders/print",
          this.purchasesOrder.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },

    goBack() {
      this.$router.push({ name: "List PurchasesOrders" });
    },
    downloadCsvFile() {
      const productsCsv = this.purchasesOrder.items
        .map((item) => {
          const productCode = item.code.split("#")[1];
          const quantity = item.quantity;
          return `${productCode};${quantity}`;
        })
        .join("\n");

      const blob = new Blob([productsCsv], { type: "text/csv;charset=utf-8;" });

      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        "0"
      )}-${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}-${today.getFullYear()}`;

      const fileName = `order_express_${formattedDate}.csv`;

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.open("https://hamster.megaburo.ca/commande-rapide", "_blank");
    },
    downloadCsvFileWithHeader() {
      const header = "Sku,Quantite";

      const productsCsv = this.purchasesOrder.items
        .map((item) => {
          const productCode = item.code.split("##")[1];
          const quantity = item.quantity;
          return `${productCode},${quantity}`;
        })
        .join("\n");

      const csvContent = `${header}\n${productsCsv}`;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        "0"
      )}-${String(today.getMonth() + 1).padStart(
        2,
        "0"
      )}-${today.getFullYear()}`;

      const fileName = `commande_express_${formattedDate}.csv`;

      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.open(
        "https://www.pepsicopartners.com/pepsico-canada/fr_CA/CAD/cart?express=true",
        "_blank"
      );
    },

    copyTextAndOpenUrlDube() {
      const productsCsv = this.purchasesOrder.items
        .map((item) => {
          return `${item.code.split("#")[1]},${item.quantity}`;
        })
        .join("\n");

      navigator.clipboard
        .writeText(productsCsv)
        .then(() => {
          console.log("Text copied to clipboard");
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });

      window.open("https://www.dubeloiselle.ca/fr/catalogue/builder", "_blank");
    },
  },
};
</script>
