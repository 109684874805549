<template>
  <div class="mb-5">
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div>
        <base-input :label="$t('COMMON.PER_PAGE')">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div>
        <base-input :label="$t('COMMON.CATEGORIES')">
          <category-group-selector
            :allowNone="true"
            :showAll="false"
            :isOnlyModels="false"
            :placeholder="$t('COMMON.CATEGORIES')"
            @categoryChanged="
              (categoryId) => {
                selectedCategory = categoryId;
              }
            "
          />
        </base-input>
      </div>
      <div>
        <base-input
          v-model="query"
          type="search"
          prepend-icon="fas fa-search"
          :placeholder="$t('COMMON.CODE')"
          :label="$t('COMMON.CODE')"
          clearable
        />
      </div>
      <div v-if="purchasesOrder.status == ORDER_STATUS_DRAFT">
        <base-checkbox v-model="displayZeroQuantities">
          <span class="form-control-label">
            {{ $t("COMMON.DISPLAY_ZERO_LINES") }}
          </span>
        </base-checkbox>
      </div>
    </div>

    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="filteredProducts.slice(startIndex, endIndex)"
    >
      <div slot="empty" v-if="loading">
        <img src="/img/loading.gif" style="height: 100px; width: 100px" />
      </div>
      <el-table-column :label="$t('COMMON.CODE')" prop="code" min-width="200">
        <template v-slot="{ row }">
          <div v-if="row">
            <a href="#" @click.prevent="goToPurchasesOrderable(row)">
              {{ row.name }}
            </a><br />
            <div class="text-muted purchasesOrder-item-excerpt">
              <ul>
                <li>
                  {{ $t("PRODUCTS.CODE") }} :
                  {{ row.code }}
                </li>
                <li>
                  {{ $t("PRODUCTS.SKU") }} :
                  {{ row.sku }}
                </li>
                <li>
                  {{ $t("PRODUCTS.SUPPLIER_CODE") }} :
                  {{ row.supplierProduct.supplier_code }}
                </li>
                <li>
                  {{ $t("PRODUCTS.SUPPLIER_SKU") }} :
                  {{ row.supplierProduct.sku }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.QUANTITY')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          <base-input
            :disabled="purchasesOrder.status != ORDER_STATUS_DRAFT"
            v-model="itemsQuantities[row.id]"
            type="number"
            placeholder="0"
            @change="
              (event) => {
                updatePurchasesOrderItem(row, event.target.value);
              }
            "
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.FORMAT')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          <object-link :object="row.orderUnitOfMeasureUnit" />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.UNIT_PRICE')"
        prop="orderUnitOfMeasureUnitPrice"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{ $formatCurrency(row.orderUnitOfMeasureUnitPrice) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.SUBTOTAL')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{
            $formatCurrency(
              itemsByProduct[row.id]
                ? itemsByProduct[row.id].pricing.subtotal
                : 0
            )
          }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.TAXES')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{
            $formatCurrency(
              itemsByProduct[row.id]
                ? itemsByProduct[row.id].pricing.taxes.total
                : 0
            )
          }}
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.TOTAL')"
        class-name="number-column"
        label-class-name="number-column"
      >
        <template v-slot="{ row }">
          {{
            $formatCurrency(
              itemsByProduct[row.id] ? itemsByProduct[row.id].pricing.total : 0
            )
          }}
        </template>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";
import { BaseInput } from "@/components";
import { cloneDeep } from "lodash";
import defaultPurchasesOrderItem from "../defaultPurchasesOrderItem";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "purchases-order-view-details-purchases-order-item-table-v2",

  components: {
    BaseInput,
    CategoryGroupSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    const itemsQuantities = {};
    this.purchasesOrder.items.forEach((item) => {
      itemsQuantities[item.purchasesOrderable.id] = item.quantity;
    });

    const itemsByProduct = {};
    this.purchasesOrder.items.forEach((item) => {
      itemsByProduct[item.purchasesOrderable.id] = item;
    });

    return {
      pagination: {
        perPage: 500,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      loading: false,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      products: [],
      filteredProducts: [],
      itemsQuantities: itemsQuantities,
      itemsByProduct: itemsByProduct,
      query: "",
      displayZeroQuantities: this.purchasesOrder.status == ORDER_STATUS_DRAFT,
      selectedCategory: null,
    };
  },

  created() {
    this.getProducts();
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    total() {
      return this.filteredProducts.length;
    },

    startIndex() {
      return (this.pagination.currentPage - 1) * this.pagination.perPage;
    },

    endIndex() {
      return this.startIndex + this.pagination.perPage;
    },
  },

  methods: {
    async goToPurchasesOrderable(purchasesOrderable) {
      this.$router.push(this.$objectViewRoute(purchasesOrderable));
    },

    async getProducts() {
      try {
        this.loading = true;
        let params = {
          sort: "-created_at",
          filter: {
            organization: this.purchasesOrder.organization.id,
            can_be_purchased: true,
            supplier: this.purchasesOrder.issuer.id,
            warehouse: this.purchasesOrder.destinationWarehouse?.id,
          },
          include: "taxGroups,unitOfMeasure,orderUnitOfMeasureUnit,categories",
          page: {
            number: 1,
            size: 999999,
          },
        };

        await this.$store.dispatch("products/list", params);
        const productsArr = await this.$store.getters["products/list"];
        this.products = productsArr;
        this.filterProducts();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    updatePurchasesOrderItem(product, quantity) {
      let purchasesOrderItemData = cloneDeep(defaultPurchasesOrderItem);

      const foundItem = this.purchasesOrder.items.find((item) => {
        return item.purchasesOrderable.id == product.id;
      });

      if (foundItem) {
        purchasesOrderItemData = cloneDeep(foundItem);
      }

      if (foundItem && quantity == 0) {
        this.$emit("onDeletePurchasesOrderItem", foundItem.id);
        return;
      }

      purchasesOrderItemData.purchasesOrder.id = this.purchasesOrder.id;
      purchasesOrderItemData.purchasesOrderable = product;
      purchasesOrderItemData.excerpt = product.denomination;

      if (
        product.orderUnitOfMeasureUnit &&
        product.orderUnitOfMeasureUnitPrice
      ) {
        purchasesOrderItemData.unit_price = product.orderUnitOfMeasureUnitPrice;
        purchasesOrderItemData.code = product.sku || product.code;
      } else if (product.warehouseProduct) {
        purchasesOrderItemData.unit_price =
          product.warehouseProduct.buying_price;
        purchasesOrderItemData.code =
          product.warehouseProduct.sku || product.sku || product.code;
      } else if (product.supplierProduct) {
        purchasesOrderItemData.unit_price =
          product.supplierProduct.buying_price;
        purchasesOrderItemData.code =
          product.supplierProduct.sku || product.sku || product.code;
      } else {
        purchasesOrderItemData.unit_price = product.buying_price;
        purchasesOrderItemData.code = product.code;
      }

      if (product.orderUnitOfMeasureUnit) {
        purchasesOrderItemData.unitOfMeasureUnit =
          product.orderUnitOfMeasureUnit;
      }
      if (!this.purchasesOrder.has_no_taxes) {
        purchasesOrderItemData.taxGroups = product.taxGroups;
      } else {
        purchasesOrderItemData.taxGroups = [];
      }

      purchasesOrderItemData.quantity = parseInt(quantity, 10);

      this.$emit("onSubmitPurchasesOrderItemForm", purchasesOrderItemData);
    },

    filterProducts() {
      const query = this.query;
      const filteredData = this.products.filter((product) => {
        const matchesQuery =
          !this.query ||
          product.code?.toLowerCase().includes(query.toLowerCase()) ||
          product.sku?.toLowerCase().includes(query.toLowerCase()) ||
          product.name?.toLowerCase().includes(query.toLowerCase()) ||
          (product.supplierProduct?.code || "")
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          (product.supplierProduct?.sku || "")
            .toLowerCase()
            .includes(query.toLowerCase());

        const quantity = this.itemsQuantities[product.id] || 0;
        const matchesQuantityFilter = this.displayZeroQuantities
          ? true
          : quantity > 0;

        const matchCategory =
          !this.selectedCategory ||
          product.categories?.some(
            (category) => category.id === this.selectedCategory
          );

        return matchesQuery && matchesQuantityFilter && matchCategory;
      });

      this.filteredProducts = filteredData;
    },
  },

  watch: {
    purchasesOrder: {
      handler() {
        const itemsQuantities = {};
        this.purchasesOrder.items.forEach((item) => {
          itemsQuantities[item.purchasesOrderable.id] = item.quantity;
        });

        const itemsByProduct = {};
        this.purchasesOrder.items.forEach((item) => {
          itemsByProduct[item.purchasesOrderable.id] = item;
        });

        this.itemsByProduct = itemsByProduct;
        this.itemsQuantities = itemsQuantities;
        
        this.displayZeroQuantities = this.purchasesOrder.status == ORDER_STATUS_DRAFT;
      },
      deep: true,
    },

    query: {
      handler() {
        this.filterProducts();
      },
      immediate: true,
      deep: true,
    },

    displayZeroQuantities: {
      handler() {
        this.filterProducts();
      },
      immediate: true,
      deep: true,
    },

    pagination: {
      handler() {
        this.filterProducts();
      },
      immediate: false,
      deep: true,
    },

    selectedCategory: {
      handler() {
        this.filterProducts();
      },
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style>
